@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Star Jedi Hol";
  src: url("./fonts/Starjhol.ttf");
}

h1 {
  font-family: "Star Jedi Hol";
}

@layer components {
  html,
  body {
    @apply bg-black;
  }

  h1,
  h3,
  h4,
  p {
    @apply text-white;
  }
}
